<template>
  <Dialog
      v-model:visible="displayModal"
      :style="{width: '400px'}"
      :modal="true"
      :closable="false"
  >
    <login-page @enter="onLogin"/>
  </Dialog>

  <Dialog id="globalSpinnerDialog"
      v-model:visible="commonManagers.spinner.isShow"
      :modal="true"
      :closable="false"
      :show-header="false"
      content-style="background-color: transparent; "
  >
    <ProgressSpinner
        style="width:100px; height:100px"
        strokeWidth="4"
        animationDuration=".5s"
    />
  </Dialog>

  <Toast />

  <div :class="containerClass" style="min-width: 700px;" @click="onWrapperClick">
    <AppTopBar
        bc-home="/"
        :bc-items="menu.find((el) => el.label === 'Данные')?.items"
        @menuToggle="onMenuToggle"
    />

    <transition name="layout-sidebar">
      <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
        <div class="layout-logo">
          <router-link to="/">
            <div class="main-logo">
              <img alt="Logo" :src="logo" height="25"/>
              <h3 class="logo-text"><strong>АРМ Эксперта</strong></h3>
            </div>
          </router-link>
        </div>

        <AppProfile @is-auth="onLogin" />
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick"/>
      </div>
    </transition>

    <div class="layout-main">
      <router-view/>
    </div>

    <AppFooter/>
  </div>
</template>

<script>
import AppTopBar from './pageblocks/AppTopbar.vue';
import AppProfile from './pageblocks/AppProfile.vue';
import AppMenu from './pageblocks/AppMenu.vue';
import AppFooter from './pageblocks/AppFooter.vue';
import LoginPage from './components/LoginPage';
import ProgressSpinner from 'primevue/progressspinner';
import {provide, reactive, readonly} from "vue";

export default {
  setup() {
    const topBarMenus = reactive({
      resultPageMenu: {
        model: [],
        displayRulesAsTable: false,
        selectedColumns: null,
        columns: null,
        onToggle: null,
      },
    })
    //@update:modelValue="onToggle"
    provide('topBarMenus', topBarMenus)

    const commonManagers = reactive({
      spinner: {
        isShow: false,
      }
    })
    provide('commonManagers', readonly(commonManagers))

    provide('hideSpinner', () => commonManagers.spinner.isShow = false)
    provide('showSpinner', () => commonManagers.spinner.isShow = true)

    return {
      commonManagers
    }
  },
  components: {AppTopBar, AppProfile, AppMenu, AppFooter, LoginPage, ProgressSpinner},
  data() {
    return {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'Данные', icon: 'pi pi-fw pi-chart-bar',
          items: [
            {label: 'Загрузка', icon: 'pi pi-fw pi-file-excel', to: '/data/download', disabled: () => !this.$store.user,},
            {label: 'Настройка', icon: 'pi pi-fw pi-table', to: '/data/settings', disabled: () => !this.$store.user,},
            {label: 'Анализ', icon: 'pi pi-fw pi-cloud-upload', to: '/data/analysis', disabled: () => !this.$store.user,},
            {label: 'Результат', icon: 'pi pi-fw pi-sitemap', to: '/data/result',},
          ],
          alwaysExpand: true,
          visible: true
          // visible: () => this.$store.user
        },
        {
          label: 'Результат', icon: 'pi pi-fw pi-sitemap', to: '/result',
          visible: false
          // visible: () => !this.$store.user
        },
        {
          label: 'Войти', icon: 'pi pi-fw pi-user', to: '/empty',
          visible: () => !this.$store.user
        },
        {
          label: 'Выйти', icon: 'pi pi-fw pi-user', to: '/empty',
          visible: () => this.$store.user
        },
      ],
      displayModal: false,
    }
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;

        if (event.item.label === 'Войти' && !this.$store.user) {
          this.displayModal = true;
        } else if (event.item.label === 'Выйти' && this.$store.user) {
          this.displayModal = false;
          this.$auth.logout()
          this.$router.push('/data/result');
        }
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
        else
          return true;
      } else {
        return true;
      }
    },
    onLogin(data) {
      this.displayModal = data.displayModal
      this.$router.push('/')
    },
  },
  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$appState.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
    sidebarClass() {
      return ['layout-sidebar', {
        'layout-sidebar-dark': this.layoutColorMode === 'dark',
        'layout-sidebar-light': this.layoutColorMode === 'light'
      }];
    },
    logo() {
      return "favicon.ico";
    },
    isUserRegister() {
      return this.$store.user
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
}
</script>

<style lang="scss">
@import './App.scss';

#globalSpinnerDialog {
  box-shadow: none;

  .p-dialog-footer {
    display: none;
  }
}

.main-logo {
  display: flex;
  justify-content: center;
  align-items: center;

  .logo-text{
    padding: 0 10px;
    color: white;
  }
}

</style>
