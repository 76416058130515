<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <router-view v-slot="{Component}">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {provide, reactive, ref, readonly, getCurrentInstance} from "vue";
import {useToast} from "primevue/usetoast";

export default {
  // Данные и методы для чтения и преобразования данных из файла Excel
  setup() {
    const crud = getCurrentInstance().appContext.config.globalProperties.$analytycCrud
    const toast = useToast();

    const rawAnalysisData = reactive({
      headers: [],
      sheet: [],
      target: {},

      data: {},
      table: {},
    })
    provide('rawAnalysisData', readonly(rawAnalysisData))

    const setTarget = (el, range) => {
      rawAnalysisData.target[el] = range
    }
    provide('setTarget', setTarget)

    const removeTarget = (el) => {
      delete rawAnalysisData.target[el]
    }
    provide('removeTarget', removeTarget)

    const setData = (data) => {
      rawAnalysisData.data = data
    }
    provide('setData', setData)

    const setTable = (table) => {
      rawAnalysisData.table = table
    }
    provide('setTable', setTable)

    const clearAnalysisData = () => {
      rawAnalysisData.headers = []
      rawAnalysisData.sheet = []
      rawAnalysisData.target = {}

      rawAnalysisData.data = {}
      rawAnalysisData.table = {}
    }
    provide('clearAnalysisData', clearAnalysisData)

    const loadRawData = (headers, sheet) => {
      rawAnalysisData.headers = headers
      rawAnalysisData.sheet = sheet

      rawAnalysisData.includeAttributes = rawAnalysisData.sheet.map(((value, index) => index))
      rawAnalysisData.targetAttributes = [0]
    }
    provide('loadRawData', loadRawData)

    // Данные для определения статуса задач
    const tasks = ref([])
    provide('tasks', readonly(tasks))

    const getTasks = async () => {
      try {
        const res = await crud.get('/getTasks', {running: 0})
        if (res && res.data && res.data.code === 0) {
          tasks.value = res.data.tasks.map(t => {
            const task = {
              id: t.id,
              description: t.description,
              status: t.status,
              start_time: t.start_time ? new Date(t.start_time + ' UTC') : new Date()
            }
            if (t.end_time) { task.end_time = new Date(t.end_time + ' UTC') }

            return task
          })
        } else {
          throw new Error(`error code: ${res.data.code}, message: ${res.data.msg}`)
        }
      } catch (e) {
        toast.add({
          severity:'error', summary: 'Задачи не получены', detail:'Обратитесь в вашу службу поддержки', life: 5000
        });
        console.error('unknown error', e)
      }
    }
    getTasks()

    const addNewTask = (task) => {
      tasks.value.push(task)
    }
    provide('addNewTask', addNewTask)

    const updateTaskStatus = async (id) => {
      const task = tasks.value.find(task => task.id === id)
      if (task) {
        try {
          const res = await crud.get('/getStatus', {id: id})

          if (res && res.data && res.data.code === 0) {
            task.status = res.data.status
          } else {
            task.status = 'undefined'
            throw new Error(`error code: ${res.data.code}, message: ${res.data.msg}`)
          }
        } catch (e) {
          console.log('no status, toast', toast)
          toast.add({
            severity:'error', summary: 'Ошибка обновления статуса', detail:'Обратитесь в вашу службу поддержки', life: 5000
          });
          console.error('something went wrong', e)
        }
      }
    }
    provide('updateTaskStatus', updateTaskStatus)

    // Транзит правил
    const transitRules = ref({})
    provide('transitRules', transitRules)
  },
  data() {
    return {
      nestedRouteItems: [
        {
          label: 'Загрузка',
          to: '/data'
        },
        {
          label: 'Настройка',
          to: '/data/settings'
        },
        {
          label: 'Анализ',
          to: '/data/analysis'
        },
        {
          label: 'Результат',
          to: '/data/result'
        }
      ],
    }
  },
  computed: {
    isLogged() {
      return this.$store.user
    }
  },
}
</script>

<style scoped>

</style>
