<template>
	<div class="layout-footer">
    <div class="footer-logo">
      <img class="footer-logo-img" :src="logo" alt="Кластер Искусственного Интеллекта" height="20"/>
      <span class="footer-text" style="margin-left: 5px">Кластер Искусственного Интеллекта&copy; 2021</span>
    </div>
	</div>
</template>

<script>
	export default {
    computed: {
      logo() {
        return "favicon.ico"
      }
    }
	}
</script>

<style scoped lang="scss">

.footer-logo {
  display: flex;
  justify-content: left;
  align-items: flex-end;

  .footer-logo-img {
    margin: 2px 0;
  }

  .footer-text{
    margin: 0 15px;
  }
}

</style>