<template>
  <Toast />

  <div class="header-login">
    <h5>Вход</h5>
    <Button
      icon="pi pi-times"
      class="p-button-rounded p-button-danger p-button-text"
      @click="closeDialog"
    />
  </div>

  <form @submit.prevent="submitHandler">
    <div class="form-control" :class="{ invalid: errors.username }">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-user" />
        <InputText
          id="user-pass-login"
          type="text"
          v-model.trim="username"
          autofocus
        />
        <label>Введите логин</label>
      </span>
      <small v-if="errors.username">{{ errors.username }}</small>
    </div>

    <div id="password-login">
      <div class="form-control" :class="{ invalid: errors.password }">
        <span class="p-float-label p-input-icon-left">
          <i class="pi pi-lock" />
          <InputText
            id="user-pass-login"
            type="password"
            v-model.trim="password"
          />
          <label>Введите пароль</label>
        </span>
        <small v-if="errors.password">{{ errors.password }}</small>
      </div>
    </div>

    <div class="p-pt-2 btn-center">
      <Button type="submit">Войти</Button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      errors: {
        username: null,
        password: null,
      },
      correct: true,
      displayModal: false,
    }
  },
  methods: {
    isValidForm() {
      let isValid = true
      if (this.username.length === 0) {
        isValid = false
        this.errors.username = 'Логин не может быть пустым'
      } else {
        this.errors.username = null
      }

      if (this.password.length === 0) {
        isValid = false
        this.errors.password = 'Пароль не может быть пустым'
      } else {
        this.errors.password = null
      }
      return isValid
    },
    closeDialog() {
      this.displayModal = false
      this.$emit('enter', {
        displayModal: this.displayModal,
      })
    },
    async submitHandler() {
      if (this.isValidForm()) {
        // const response = await fetch(
        //   'https://vebai.eyeline.mobi/back/api/login',
        //   {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //       login: this.username,
        //       password: this.password,
        //     }),
        //   }
        // )
        
        // TODO: remove this STUB!!!
        let status = 202 //response.status
        switch (status) {
          case 202: {
            this.$auth.login(this.username)
            this.$emit('enter', {
              displayModal: this.displayModal,
            })
            this.$router.push('/data/download')
            // this.$router.forward('/data/download')
            break
          }
          case 400: {
            this.$toast.add({
              severity: 'error',
              summary: 'Error: 400',
              life: 3000,
              closable: false,
            })
            break
          }
          case 403: {
            this.$toast.add({
              severity: 'error',
              summary: 'Некорректно введен логин или пароль.',
              life: 3000,
              closable: false,
            })
            break
          }
          case 404: {
            this.$toast.add({
              severity: 'error',
              summary: 'Error: 404',
              life: 3000,
              closable: false,
            })
            break
          }
          case 500: {
            this.$toast.add({
              severity: 'error',
              summary: 'Error: 500',
              life: 3000,
              closable: false,
            })
            break
          }
          case 502: {
            this.$toast.add({
              severity: 'error',
              summary: 'Error: 502',
              life: 3000,
              closable: false,
            })
            break
          }
          default: {
            alert('Неизвестная ошибка')
            break
          }
        }
      } // конец if
    },
  },
}
</script>

<style scoped>
.form-control small {
  color: #e53935;
}

.form-control.invalid input {
  border-color: #e53935;
}

#user-pass-login {
  width: 100%;
}

#password-login {
  margin-top: 15px;
}

.header-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 0.1rem;
}

.btn-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-input-icon-left {
  width: 100%;
  margin: 3px 0px 2px 0px;
}
</style>
