export default class AuthService {
    constructor(app) {
        this.app = app
    }

    initUser() {
        const name = localStorage.getItem('user')
        if (name) {
            console.log('user inited: ' + name)
            return {name: name}
        } else {
            console.log('user not found')
            return null
        }
    }

    login(user) {
        localStorage.setItem('user', user);
        this.app.config.globalProperties.$store.user = {name: user}

        console.log('user logged in: ' + user)
    }

    clearUser() {
        localStorage.removeItem('user')
        this.app.config.globalProperties.$store.user = null
    }

    logout() {
        // если будем хранить токены или что-то ещё, тут будет работа с ними
        // пока только очищаем локальные данные
        this.clearUser()

        console.log('user logged out')
    }
}