import axios from "axios";

class CrudService {

    constructor(app, base_url) {
        this.requestAccess = true;
        this.base_url = base_url;
        this.app = app;
    }

    async post(link, params) {
        return this.sendRequest(`${this.base_url + (link ? link : '')}`, params, 'post')
    }

    async get(link, params) {
        return this.sendRequest(`${this.base_url + (link ? link : '')}`, params, 'get')
    }

    async put(link, params) {
        return this.sendRequest(`${this.base_url + (link ? link : '')}`, params, 'put')
    }

    async sendRequest(link, params, method) {
        let requestResult
        try {
            if (method === 'get') {
                requestResult = axios({
                    method: method,
                    url: link,
                    headers: {
                        token: localStorage.getItem('token')
                    },
                    params: params ? params : null
                })
            } else if (method === 'post') {
                requestResult = axios.post(link, params, {
                    headers: {
                        token: localStorage.getItem('token')
                    }
                })
            } else if (method === 'put') {
                requestResult = axios.put(link, params, {
                    headers: {
                        token: localStorage.getItem('token')
                    }
                })
            }
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.code && error.response.data.code !== 0) {
                // Тут будем обрабатывать ошибки, в том числе и проблемы авторизации
            }
            console.error('Ошибка обработки запроса', error)
        }

        return requestResult
    }

}

export default CrudService;
