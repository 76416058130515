<template>
  <Dialog
      v-model:visible="displayModal"
      :style="{width: '400px'}"
      :modal="true"
      :closable="false"
  >
    <login-page @enter="onLogin"/>
  </Dialog>

	<div class="layout-profile" v-bind="$attrs">
		<div>
      <i class="pi pi-user" style="fontSize: 3rem" :style="$store.user ? 'color: green' : 'color: grey'"/>
    </div>
    <br />
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">
        {{$store.user ? $store.user.name : 'Гость'}}
      </span>
			<i class="pi pi-fw pi-cog"></i>
		</button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li>
          <button class="p-link" @click="onLoginClick"><i class="pi pi-fw pi-power-off"></i>
            <span>{{ isRegistered ? 'Выйти' : 'Войти' }}</span>
          </button>
        </li>
      </ul>
    </transition>
	</div>
</template>

<script>
	import LoginPage from "../components/LoginPage";

  export default {
    components: {'LoginPage': LoginPage},
		data() {
			return {
				expanded: false,
        displayModal: false,
			}
		},
    computed: {
      isRegistered() {
        return this.$store.user
      }
    },
		methods: {
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			},
      onLoginClick() {
        if (this.$store.user) {
          this.logout()
        } else this.showDialog()
      },
      showDialog() {
        this.displayModal = true
      },
      onLogin(data) {
        this.Auth = data.isAuth
        this.displayModal = data.displayModal
        this.$emit('is-auth',{
          displayModal: data.displayModal
        })
        this.$router.push('/')
      },
      logout() {
        this.$auth.logout()
        this.$emit('is-auth',{
        })
        this.$router.push('/')
      }
		},
	}
</script>

<style scoped>

</style>