import { createRouter, createWebHashHistory } from 'vue-router'
// import { createRouter, createWebHistory } from 'vue-router'
import DataPage from '@/pages/DataPage'

export const routes = [
  {
    path: '/data',
    name: 'data',
    component: DataPage,
    children: [
      {
        path: '/data/download',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/pages/data/DownloadPage'),
      },
      {
        path: '/data/settings',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/pages/data/SettingsPage'),
      },
      {
        path: '/data/analysis',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/pages/data/AnalysisPage'),
      },
      {
        path: '/data/result',
        alias: '/',
        component: () => import('@/pages/data/ResultPage'),
      },
    ],
  },
  {
    path: '/:notFound(.*)',
    name: 'empty',
    component: () => import('./pages/EmptyPage.vue'),
  },
  {
    path: '/onlyRegisterPage',
    name: 'onlyRegisterPage',
    component: () => import('./pages/OnlyRegisterPage'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  //   history: createWebHistory(),
  routes,
})

router.beforeEach(function(to, from, next) {
  window.scrollTo(0, 0)
  next()
})

router.beforeEach((to) => {
  if (to.meta.requiresAuth && !localStorage.getItem('user')) {
    return '/onlyRegisterPage'
  }
  if (to.meta.isAuth) {
    return '/empty'
  }
  if (to.path === '/result' && localStorage.getItem('user')) {
    return '/data/result'
  }
  return true
})

export default router
