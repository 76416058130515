<template>
  <div class="layout-topbar">
    <div class="topbar-container">
      <div>
        <button
            class="p-link layout-menu-button"
            @click="onMenuToggle"
        >
          <span class="pi pi-bars"></span>
        </button>
      </div>

      <div class="bread-crumbs-menu">
        <div v-if="canShowDataPages">
          <Breadcrumb :home="home" :model="breadcrumbs">
            <template #item="{item}">
              <router-link :to="item.to" :class="item.icon" custom v-slot="{href, navigate}">
                <a v-bind="$attrs"
                   :href="href"
                   @click="navigate"
                   :class="item.icon"
                >{{ item.label }}</a>
              </router-link>
            </template>
          </Breadcrumb>
        </div>

        <div v-if="canShowResultMenu && topBarMenus.resultPageMenu.model">
          <Menubar :model="topBarMenus.resultPageMenu.model" style="z-index: 2;" >
            <template #end>
              <multi-select
                  style="max-width: 18rem"
                  v-if="!topBarMenus.resultPageMenu.displayRulesAsTable"
                  :modelValue="topBarMenus.resultPageMenu.selectedColumns"
                  @update:modelValue="topBarMenus.resultPageMenu.onToggle"
                  :options="topBarMenus.resultPageMenu.columns"
                  optionLabel="header"
                  placeholder="Выбрать столбцы"
              />
            </template>
          </Menubar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from 'primevue/breadcrumb'
import Menubar from 'primevue/menubar'
import {inject} from "vue";
import MultiSelect from "primevue/multiselect";

export default {
  setup() {
    const topBarMenus = inject('topBarMenus')

    return {
      topBarMenus
    }
  },
  mounted() {
    console.log('topBarMenus', this.topBarMenus.resultPageMenu)
  },
  components: {Breadcrumb, Menubar, MultiSelect},
  props: {
    bcHome: { // bc - breadcrumb
      type: String,
      required: true,
    },
    bcItems: {
      type: Array,
      required: true,
    }
  },
  emits: ['menuToggle'],
  data() {
    return {}
  },
  methods: {
    onMenuToggle(event) {
      this.$emit('menuToggle', event);
    }
  },
  computed: {
    home() {
      return { icon: 'pi pi-home', to: this.bcHome }
    },
    breadcrumbs() {
      return this.bcItems.map((el) => (
          {
            label: el.label,
            to: el.to,
          }
      ))
    },
    canShowDataPages(){
      return this.$store.user && (this.$route.path.startsWith('/data') || this.$route.path === '/')
    },
    canShowResultMenu() {
      return this.$route.path === '/data/result' || this.$route.path === '/'
    },
  }
}
</script>

<style scoped lang="scss">

.topbar-container {
  height: 23px;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .bread-crumbs-menu {
    width: 100%;
    display:flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

.p-breadcrumb {
  padding: 0 14px;
  background-color: transparent;
  border-color: transparent;
}

.p-breadcrumb a {
  color: var(--primary-color-text);
}

.p-breadcrumb a:hover {
  text-decoration: underline;
}

::v-deep(.pi-chevron-right:before) {
  color: var(--primary-color-text);
}

.p-menubar {
  padding: 0;
  background-color: transparent;
  border-color: transparent;
}

::v-deep(.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text) {
  color: var(--primary-color-text);
}

::v-deep(.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon) {
  color: var(--primary-color-text);
}

::v-deep(.p-menubar
    .p-menubar-root-list
    .p-menuitem
    .p-menuitem-link
    .p-submenu-icon) {
  color: var(--primary-color-text);
}
</style>