<template>
	<div class="layout-menu-container">
		<AppSubmenu
        :items="model"
        class="layout-menu"
        :root="true"
        @menuitem-click="onMenuItemClick"
        :isAuth="isAuth"/>
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
	props: {
		model: Array,
    isAuth: Boolean,
	},
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        }
    },
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>

<style scoped>

</style>