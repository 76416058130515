import { createApp, reactive } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/saga-purple/theme.css' //theme
import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css' //icons
import 'primeflex/primeflex.css'
import Tooltip from 'primevue/tooltip'
import BadgeDirective from 'primevue/badgedirective'
import 'primevue/resources/themes/saga-blue/theme.css'
import '@/assets/layout/layout.scss'
import router from '@/router'
import config from '../public/config.json'
import CrudService from '@/service/CrudService'
import ConfirmationService from 'primevue/confirmationservice'
import Ripple from 'primevue/ripple'
import ToastService from 'primevue/toastservice'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import AuthService from '@/service/AuthService'

// Bootstrap icons. Needed in result page
import 'bootstrap-icons/font/bootstrap-icons.css'

const app = createApp(App)

app.config.globalProperties.$analytycCrud = new CrudService(
  app,
  config.analysis_url
)
app.config.globalProperties.$auth = new AuthService(app)
app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' })
app.config.globalProperties.$store = reactive({
  user: app.config.globalProperties.$auth.initUser(),
})

app.use(PrimeVue, { ripple: true })
app.use(ConfirmationService)
app.use(ToastService)
app.use(router)

app.component('Button', Button)
app.component('Toast', Toast)
app.component('InputText', InputText)
app.component('Dialog', Dialog)

app.directive('tooltip', Tooltip)
app.directive('ripple', Ripple)
app.directive('badge', BadgeDirective)

app.mount('#app')
